import { z } from 'zod'

export const SessionDataSchema = z.object({
  sessionId: z.coerce.string({
    required_error: 'sessionId required',
    invalid_type_error: 'Invalid sessionId',
  }),
  callbackUrl: z
    .string({
      required_error: 'callbackUrl required',
      invalid_type_error: 'Invalid callback URL',
    })
    .url('Invalid callback URL'),
  paymail: z
    .string({
      required_error: 'paymail required',
      invalid_type_error: 'Invalid paymail',
    })
    .email('Invalid paymail'),
  outputs: z.string().optional(),
  email: z
    .string({
      required_error: 'email required',
      invalid_type_error: 'Invalid email',
    })
    .email('Invalid email'),
  product: z.string({
    required_error: 'product required',
    invalid_type_error: 'Invalid product',
  }),
  price: z.coerce
    .number({ invalid_type_error: 'Invalid price' })
    .min(0, 'price must be greater than 0')
    .optional(),
  headingText: z.string().optional(),
  subheadingText: z.string().optional(),
  hidePrice: z.coerce
    .boolean({ invalid_type_error: 'Invalid hidePrice' })
    .optional(),
  theme: z
    .enum(['dark', 'light'], {
      invalid_type_error: "Theme must either be 'dark' or 'light'",
    })
    .optional(),
  redirectUrl: z.string().url('Invalid redirect URL').optional(),
  tickers: z
    .string()
    .transform((str) => str.split(',').map((item) => item.trim()))
    .refine(
      (arr) => {
        return z.array(z.enum(['BTC', 'BCH', 'BSV', 'SIGNET'])).safeParse(arr)
          .success
      },
      { message: 'Invalid tickers ( must be either BTC, BCH, BSV, or SIGNET)' },
    )
    .optional()
    .or(z.array(z.enum(['BTC', 'BCH', 'BSV', 'SIGNET'])).optional()),
})
