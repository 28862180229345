type EventMap = {
  [K: string]: any
}

export class EventBus<T extends EventMap> {
  private listener:
    | ((event: keyof T, cb: (data: unknown) => void) => void)
    | null = null
  private _disconnect: (() => void) | null = null
  private dispatcher: ((event: keyof T, data: T[keyof T]) => void) | null = null

  constructor(
    options:
      | {
          listener: (event: keyof T, cb: (data: unknown) => void) => void
          disconnect: () => void
        }
      | { dispatcher: (event: keyof T, data: T[keyof T]) => void },
  ) {
    if ('listener' in options) {
      this.listener = options.listener
      this._disconnect = options.disconnect
    }
    if ('dispatcher' in options) {
      this.dispatcher = options.dispatcher
    }
  }

  on<K extends keyof T>(event: K, cb: (data: T[K]) => void): void {
    if (!this.listener) {
      throw new Error(
        'Missing listener (did you provide one when instantiating the EventBus?)',
      )
    }
    this.listener(event, (d) => {
      if (typeof d === 'object') {
        if (d && 'data' in d && d.data) {
          if (typeof d.data === 'object') {
            cb(d.data as T[K])
          } else if (typeof d.data === 'string') {
            cb(JSON.parse(d.data))
          }
        } else if (d) {
          cb(d as T[K])
        }
      }
    })
  }

  disconnect(): void {
    if (!this._disconnect) {
      throw new Error(
        'Missing disconnect function (did you provide one when instantiating the EventBus)',
      )
    }
    this._disconnect()
  }

  dispatch<K extends keyof T>(event: K, data: T[K]): void {
    if (!this.dispatcher) {
      throw new Error(
        'Missing dispatcher (did you provide one when instantiating the EventBus)',
      )
    }
    this.dispatcher(event, data)
  }
}
