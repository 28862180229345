import React from 'react'
import ReactDOM from 'react-dom'
import PayPlugin from './PayPlugin'

declare global {
  interface Window {
    CANONIC_PAY: {
      reload: () => void
    }
  }
}

const MAX_RETRY_DURATION_MS = 10000
const RETRY_INTERVAL = 250
const MAX_RETRIES = MAX_RETRY_DURATION_MS / RETRY_INTERVAL

function setupApp(currentTry = 0) {
  const container = document.querySelector('#canonicPay')

  if (!container && currentTry < MAX_RETRIES) {
    setTimeout(() => setupApp(currentTry++), RETRY_INTERVAL)
  } else if (!container) {
    console.error('Missing div with id #canonicPay!')
  } else {
    ReactDOM.render(<PayPlugin />, container)
  }
}

if (document.readyState !== 'loading') {
  setupApp()
} else {
  window.addEventListener('load', () => setupApp())
}

window.CANONIC_PAY = {
  reload: setupApp,
}
