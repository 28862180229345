import { useMemo } from 'react'

import React, { useEffect, useState } from 'react'
import { z } from 'zod'
import { SessionDataSchema } from 'lib/schemas'
import { twMerge } from 'tailwind-merge'
import { iframeResizer } from 'iframe-resizer'
import { EventBus } from 'lib/event-bus'
import { IFrameEventMap } from 'types/interfaces'

export default function PayPlugin() {
  const slot = document.querySelector('#canonicPay') as HTMLDivElement
  if (!slot) {
    return (
      <div data-testid="slotMissingError">
        Missing div with id of canonicPay!
      </div>
    )
  }

  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [iframeSrc, setIframeSrc] = useState<string | null>(null)

  // Only update on initial mount!
  const [lockedSessionData, setLockedSessionData] =
    useState<z.infer<typeof SessionDataSchema>>()

  const dev = process.env.NODE_ENV === 'development'

  const baseUrl = dev ? 'http://localhost:3003' : 'https://pay.canonic.xyz'

  const config = useMemo(() => {
    try {
      const config = SessionDataSchema.parse(slot.dataset)

      return config
    } catch (err) {
      if (err instanceof z.ZodError) {
        setError(err.issues.map((e) => e.message).join(', '))
      } else {
        setError('Invalid config')
      }
    }
  }, [])

  const [serverSessionId, setServerSessionId] = useState('')

  const createServerSession = async () => {
    try {
      if (serverSessionId) return serverSessionId
      if (!lockedSessionData) throw Error("Can't create session (missing data)")

      setLoading(true)
      const res = await fetch(`${baseUrl}/api/session`, {
        method: 'POST',
        body: JSON.stringify(lockedSessionData),
        headers: { 'content-type': 'application/json' },
      })
      const data: { serverSessionId: string } | { error: string } =
        await res.json()

      if ('error' in data) throw Error(data.error)

      setServerSessionId(data.serverSessionId)

      setLoading(false)
      return data.serverSessionId
    } catch (err) {
      console.error('Failed to create session', err)
      setError(err instanceof Error ? err.message : JSON.stringify(err))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLockedSessionData(config)
  }, [])

  useEffect(() => {
    const init = async () => {
      if (lockedSessionData) {
        const url = await getIframeUrl()
        setIframeSrc(url.toString())

        const eventBus = new EventBus<IFrameEventMap>({
          listener: (event, cb) => {
            window.addEventListener(
              'message',
              (e) => {
                if (e.origin !== baseUrl) return
                if (e.data.type === event) {
                  cb(e.data.data)
                }
              },
              false,
            )
          },
          disconnect: () => {},
        })

        eventBus.on('success_redirect', ({ url }) => {
          window.location.href = url
        })
      }
    }

    init()
  }, [lockedSessionData])

  const getIframeUrl = async () => {
    const serverSessionId = await createServerSession()

    if (!serverSessionId) throw Error('Missing server session id!')

    dev && console.log(`Server session created with id ${serverSessionId}`)

    const params = new URLSearchParams({
      serverSessionId,
      hostname: window.location.hostname,
    })

    const url = new URL(`${baseUrl}/pay`)
    url.search = params.toString()

    return url
  }

  const iframeRef = React.useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (iframeRef.current && iframeSrc) {
      iframeResizer(
        {
          autoResize: true,
          checkOrigin: [
            'http://localhost:3003',
            'https://pay.canonic.xyz',
            window.location.hostname,
          ],
        },
        iframeRef.current,
      )
    }
  }, [iframeSrc])

  if (error) {
    if (process.env.NODE_ENV !== 'test') {
      console.error(error)
    }
    return (
      <div
        data-testid="error"
        className="tw-px-2 tw-py-1 tw-mt-2 tw-text-red-400 tw-border tw-border-red-400 tw-whitespace-nowrap"
      >
        Canonic Pay Error: {error}
      </div>
    )
  }

  return loading ? (
    <Spinner />
  ) : (
    <>
      {iframeSrc && (
        <div className="w-full">
          <iframe
            ref={iframeRef}
            data-testid="iframe"
            src={iframeSrc}
            className="min-w-full"
            width={`100%`}
            allow={`clipboard-write`}
          />
        </div>
      )}
    </>
  )
}

interface SpinnerProps {
  className?: string
}

function Spinner(props: SpinnerProps) {
  const defaultClassName = 'tw-h-8 tw-w-8 tw-text-theme-purple tw-block'

  return (
    <span className={twMerge(defaultClassName, props.className)}>
      <span
        className={`tw-border-b-transparent tw-border-current tw-rounded-full tw-box-border tw-border-solid tw-border-2 tw-inline-block tw-animate-spin tw-h-full tw-w-full`}
      />
    </span>
  )
}
